import React, { useState } from 'react';
import "./App.css"

const Calculator = ({onSubmit}) => {
    const [worth, setWorth] = useState(0);
  const [formData, setFormData] = useState({
    profession: '',
    location: '',
    relationships: 0,
    skinColor: 'fair',
    previousMarriages: 0,
    age: '',
    fage: '',
    height: 60,
    education: '',
    workingStatus: '',
    nri: 0,
    maleIncome: 0
  });

  const calculateWorth = () => {
    // Define weights for each factor
    const weights = {
      profession: {
        'software_engineer': 2,
        'engineer': 1.3,
        'doctor': 3,
        'CA': 1.1,
        'Government Official (IAS,IPS)': 6
      },
      location: {
        'same_state': 142473,
        'different_state': 100
      },
      relationships: 288107,
      skinColor: {
        'fair': 40000,
        'dark': 147514
      },
      previousMarriages: 393068,
      ageDifference: 50,
      height: 40000,
      education: {
        '10th_pass': 1,
        '12th_pass': 1.2,
        'graduate': 2,
        'post_graduate': 4
      },
      workingStatus: {
        'working': 300000,
        'housewife': 0
      },
      NRI: 2505600
    };

    let totalWorth = 0;
    totalWorth += weights.location[formData.location];
    totalWorth += formData.relationships * weights.relationships;
    totalWorth += weights.skinColor[formData.skinColor];
    totalWorth += formData.previousMarriages * weights.previousMarriages;
    if (parseInt(formData.fage) > parseInt(formData.age)) {
      const difference = parseInt(formData.fage) - parseInt(formData.age);
      totalWorth += Math.pow(totalWorth, 1 + (0.1 * difference));
    } else {
      totalWorth += 0;
    }
    totalWorth += (weights.height - 160) * (parseInt(formData.height));
    totalWorth += weights.workingStatus[formData.workingStatus];
    totalWorth += formData.maleIncome * weights.profession[formData.profession] * weights.education[formData.education];
    const randomMultiplier = Math.random() * (1.4 - 0.9) + 0.9;
    totalWorth=totalWorth*randomMultiplier
    setWorth(totalWorth);
    console.log(totalWorth)

    return totalWorth
  };

  const handleHeightChange = (e) => {
    setFormData({ ...formData, height: parseInt(e.target.value) });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: inputValue });
  };

 

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col justify-center items-center">
      <h1 className="text-4xl font-bold mb-4 text-center text-blue-600">Blessings Calculator</h1>
      <div className="banner-container">
        <img
          src="https://res.cloudinary.com/dbctnfsy1/image/upload/v1716628728/cjzydgpjb7osmsxxz8l5.png"
          alt="Banner"
          className="banner rounded-lg shadow-lg"
        />
      </div>
      <div className="form-container bg-white p-8 rounded-lg shadow-lg mt-8">
        <h2 className="text-2xl font-semibold mb-4 col-span-2">Evaluate your Blessing Amount</h2>
        <form>
          <label className="block mb-2">
            Profession (Male):
            <select
              name="profession"
              value={formData.profession}
              onChange={handleInputChange}
              className="block border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-blue-500"
            >
              <option value="">Select</option>
              <option value="software_engineer">Software Engineer</option>
              <option value="engineer">Engineer</option>
              <option value="doctor">Doctor</option>
              <option value="CA">Chartered Accountant</option>
              <option value="Government Official (IAS,IPS)">Government Official (IAS,IPS)</option>
            </select>
          </label>
          <div className="mb-4">
            <label className="block mb-2">Location:</label>
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="location"
                  value="same_state"
                  checked={formData.location === 'same_state'}
                  onChange={handleInputChange}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Same State</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  name="location"
                  value="different_state"
                  checked={formData.location === 'different_state'}
                  onChange={handleInputChange}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Different State</span>
              </label>
            </div>
          </div>
          <label className="block mb-2">
            Number of previous relationships (of female):
            <input
              type="number"
              name="relationships"
              value={formData.relationships}
              onChange={handleInputChange}
              className="block border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-blue-500"
            />
          </label>
          <div className="mb-4">
            <label className="block mb-2">Skin Color (Female):</label>
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="skinColor"
                  value="fair"
                  checked={formData.skinColor === 'fair'}
                  onChange={handleInputChange}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Fair</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  name="skinColor"
                  value="dark"
                  checked={formData.skinColor === 'dark'}
                  onChange={handleInputChange}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Dark</span>
              </label>
            </div>
          </div>
          <label className="block mb-2">
            Number of previous marriages (of female):
            <input
              type="number"
              name="previousMarriages"
              value={formData.previousMarriages}
              onChange={handleInputChange}
              className="block border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-blue-500"
            />
          </label>
          <div className="mb-4">
            <label className="block mb-2">Male Age:</label>
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleInputChange}
              className="block border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-blue-500"
            />
            <label className="block mb-2">Female Age:</label>
            <input
              type="number"
              name="fage"
              value={formData.fage}
              onChange={handleInputChange}
              className="block border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Height (Male):</label>
            <input
              type="range"
              name="height"
              min="60"
              max="84"
              step="1"
              value={formData.height}
              onChange={handleHeightChange}
              className="block w-full appearance-none bg-gray-200 h-2 rounded-full focus:outline-none focus:ring focus:border-blue-500"
            />
            <p className="text-center">{Math.floor(formData.height / 12)}' {formData.height % 12}" ({formData.height} inches)</p>
          </div>
          <label className="block mb-2">
            Education (Male):
            <select
              name="education"
              value={formData.education}
              onChange={handleInputChange}
              className="block border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-blue-500"
            >
              <option value="">Select</option>
              <option value="10th_pass">10th pass</option>
              <option value="12th_pass">12th pass</option>
              <option value="graduate">Graduate</option>
              <option value="post_graduate">Post Graduate</option>
            </select>
          </label>
          <label className="block mb-2">
            Male Income:
            <input
              type="number"
              name="maleIncome"
              value={formData.maleIncome}
              onChange={handleInputChange}
              className="block border border-gray-300 rounded-md py-2 px-3 mt-1 focus:outline-none focus:ring focus:border-blue-500"
            />
          </label>
          <div className="mb-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="nri"
                checked={formData.nri}
                onChange={handleInputChange}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2">Is male NRI?</span>
            </label>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Working Status (Female):</label>
            <div>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="workingStatus"
                  value="working"
                  checked={formData.workingStatus === 'working'}
                  onChange={handleInputChange}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Working</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  name="workingStatus"
                  value="housewife"
                  checked={formData.workingStatus === 'housewife'}
                  onChange={handleInputChange}
                  className="form-radio h-5 w-5 text-blue-600"
                />
                <span className="ml-2">Housewife</span>
              </label>
            </div>
          </div>
          <button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
            onClick={() => onSubmit(calculateWorth)}
          >
            Calculate Blessings Deserved
          </button>
        </form>
      </div>
    </div>
  );
};

const ResultPage = ({ worth }) => {
  function formatNumberToIndianScheme(number) {
    const numStr = number.toString();
    const [integerPart, decimalPart] = numStr.split('.');
  
    // Handle the integer part
    const lastThree = integerPart.slice(-3);
    const otherNumbers = integerPart.slice(0, -3);
    const formattedIntegerPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherNumbers ? "," : "") + lastThree;
  
    // Combine the integer part with the decimal part (if any)
    return decimalPart ? formattedIntegerPart + '.' + decimalPart : formattedIntegerPart;
  }
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col justify-center items-center">
      <h1 className="text-4xl font-bold mb-4 text-center">Result Page</h1>
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Blessing Worth: {formatNumberToIndianScheme(parseInt(worth))} Rupees</h2>
      </div>
    </div>
  );
};

const ParentComponent = () => {
  const [worth, setWorth] = useState(0);

  const handleCalculatorSubmit = (worth) => {
    console.log("Worth received",worth)
    setWorth(worth);
  };

  return (
    <div>
      <Calculator onSubmit={handleCalculatorSubmit} />
      {worth !== 0 && <ResultPage worth={worth} />}
    </div>
  );
};

export default ParentComponent;
